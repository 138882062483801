export const RRSSLinksData = [
    // {
    //     icon: 'fa-brands fa-facebook-f',
    //     link: 'https://www.facebook.com/listica.costarica',
    // },
    {
        icon: 'fa-brands fa-linkedin-in',
        link: 'https://www.linkedin.com/company/listica/',
    },
    // {
    //     icon: 'fa-brands fa-instagram',
    //     link: 'https://instagram.com/listica.costarica?igshid=YmMyMTA2M2Y=',
    // }, 
    // {
    //     icon: 'gettr',
    //     link: 'https://gettr.com/user/listica',
    // }, 
    {
        icon: 'fa-brands fa-whatsapp',
        link: 'https://wa.me/50687297443',
    },
    // {
    //     icon: 'fa-brands fa-google',
    //     link: 'https://g.co/kgs/5rdyHC',
    // }, 
    {
        icon: 'fa-brands fa-telegram',
        link: 'https://t.me/listica',
    }, {
        icon: 'fa-solid fa-envelope',
        link: 'mailto:info@listica.com',
    },
    // {
    //     icon: 'fa-solid fa-phone',
    //     link: 'tel:(+506) 87297443',
    // },
]

export default RRSSLinksData;
